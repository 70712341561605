import React from 'react';
import { useSelector } from 'react-redux';

import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { H1 } from '../elements';
import { Container, Post, Seo } from '../components';
const SinglePost = ({ data, location }) => {
  const locale = useSelector((state) => state.localeString);
  const isDark = useSelector((state) => state.isDark);

  return (
    <Container pathname={location.pathname} locale={locale} isDark={isDark}>
      <Seo />
      <Post isDark={isDark}>
        <H1 margin="0 0 0 0">{data.mdx.frontmatter.title}</H1> {' '}
        <MDXRenderer>{data.mdx.body}</MDXRenderer>
      </Post>
    </Container>
  );
};
export default SinglePost;

export const pageQuery = graphql`
  query SinglePostTemplateQuery($id: String!) {
    mdx(id: { eq: $id }) {
      body
      frontmatter {
        date
        excerpt
        slug
        title
      }
    }
  }
`;
